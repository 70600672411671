@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url(https://fonts.googleapis.com/css?family=Sanchez:400italic,400);
@import "../../breakpoints.scss";


.blurbs{
  max-width: 100%;

  .content{
    max-width: 800px;
    margin: 30px auto;
  }

  h2{
    margin-top: 80px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    width: 100%;
  }

  .slick-dots li button:before{
    color: white;
    font-size: 10px;
  }

  .slick-prev, .slick-next{
    opacity: 0.5;
  }

  blockquote {
    position: relative;
    font-family: 'Sanchez', serif;
    font-size: 26px;
    line-height: 40px;
    padding-right: 35px;
    @include for-phone-only{
      //padding: 20px 50px 20px 30px;
    }

    .country{
      opacity: 0.3;
    }

    .grade{
      font-size: 32px;
      color: #bc8956;
    }

    footer {
      font-family: 'Noto Sans', sans-serif;
      font-size: 0.6em;
      font-weight: 700;
      color: darken(#fff, 15%);
      float: right;

      a{
        color: darken(#fff, 15%);

        &:hover{
          color: #bc8956;
        }
      }

      &:before {
        content: '\2015';
      }
    }
    &:after {
      content: '\201D';
      position: absolute;
      top: 0.28em;
      right: 0;
      font-size: 6em;
      font-style: italic;
      color: darken(#fff, 7%);
      z-index: -1;
      opacity: 0.6;
    }
  }

}