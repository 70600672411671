@import "../../breakpoints.scss";

.videos{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;


  @include for-tablet-landscape-up {
    padding: 0;
    padding-bottom: 100px;
  }

  h2{
    margin-top: 80px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    width: 100%;

  }

  .video{
    margin: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @include for-tablet-landscape-up {
      flex-direction: row;
      align-items: stretch;
    }

    .embed{
      flex: 1;
      text-align: right;
      width: 100%;

      @include for-tablet-landscape-up {
        width: auto;
      }

      iframe{
        display: inline-block;
        width: 100%;

        @include for-tablet-landscape-up {
          max-width: 500px;
        }
      }
    }

    .description{
      margin-top: 20px;
      @include for-tablet-landscape-up {
        margin-left: 60px;
        margin-top: 0;
      }

      flex: 1;
      p{
        max-width: 400px;
        color: rgba(255,255,255, 0.6);
        line-height: 25px;

      }
    }
  }

}