@mixin for-phone-only {
	@media (max-width: 599px) { @content; }
	@media
	only screen and (-webkit-min-device-pixel-ratio: 1.3) and (max-width: 1000px),
	only screen and (min-device-pixel-ratio: 1.3)and (max-width: 1000px),
	only screen and (min-resolution: 120dpi) and (max-width: 1000px)
	{
		@content;
	}
}
@mixin for-tablet-portrait-up {
	@media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
	@media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
	@media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
	@media (min-width: 1800px) { @content; }
}