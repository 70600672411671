@import "breakpoints.scss";

* {
	box-sizing: border-box;
}

body{
	background: black;
	background-image: url('./images/tillian_bg.jpg');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center center;
	font-family: 'Lato', sans-serif;
	color: white;
	padding: 0;
	margin: 0;
}

#root{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main{
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 50px;
	display: flex;

	@include for-phone-only{
		flex-direction: column;
		padding: 20px;
	}

	.cover{
		flex: 1;

		img{
			width: 100%;
			height: 100%;
			border: 2px solid rgba(255,255,255, 0.3);
			max-width: 100vw;

		}
	}

	.hero{
		flex: 1;
		margin-left: 30px;
		text-align: center;
		font-weight: 300;

		@include for-phone-only{
			margin: 0;
		}

		h1{
			font-size: 50px;
			font-weight: 400;
			margin-bottom: 10px;
		}

		h2{
			font-size: 18px;
			font-weight: 300;
			margin: 0px;
			
		}

		h2.preorderTitle{
			font-size: 32px;
			color: #bc9d28;
			font-weight: 400;
			margin: 25px;
			text-transform: uppercase;
		}
		

		.cta{
			border: none;
			font-family: inherit;
			font-size: 13px;
			background: none;
			cursor: pointer;
			display: inline-block;
			display: none;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: 700;
			outline: none;
			position: relative;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
			
			// padding: 25px 120px 25px 60px;
			padding: 18px 60px 15px 40px;
			overflow: hidden;
			background: #bc9d28;
			color: #fff;
			margin: 30px;
			margin-top: 15px;

			@include for-phone-only{
				padding: 20px 50px 20px 30px;
			}


			&:before{
				content: '→';
				position: absolute;
				height: 100%;
				top: -1px;
				line-height: 3;
				font-size: 140%;
				width: 60px;
				right: 10px;
				z-index: 2;

				@include for-phone-only{
					top: -4px;
					right: 0px;
				}

			}

			&:after{
				width: 30%;
				height: 200%;
				background: rgba(255,255,255,0.1);
				z-index: 1;
				right: 0;
				top: 0;
				margin: -5px 0 0 -5px;
				transform-origin: 0 0;
				transform: rotate(-20deg);
				content: '';
				position: absolute;
				transition: all 0.3s;
			}

			&:hover{
				background: #bc8956;

				&:after {
					width: 40%;
				}
			}
		}
	}
}

.preorder{
	display: flex;
	flex-wrap: wrap;

	a{
		font-size: 0;
		display: inline-block;
		width: 150px;
		height: 50px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		margin-left: 10px;
		margin-bottom: 10px;
	}

	@include for-phone-only{
		align-items: start;

		a{
			width: auto;
			flex: 1 0 47%;
			margin-left: 6px;
			max-width: 47%;
		}
	}


	a.bandcamp{
		background-image: url('./images/bandcamp-badge.png');
	}

	a.apple{
		background-image: url('./images/apple-music-badge.png');
	}

	a.amazon{
		background-image: url('./images/amazon-music.png');
	}

	a.spotify{
		background-image: url('./images/spotify-badge.png');
	}

	a.deezer{
		background-image: url('./images/deezer-badge.png');
	}

	a.tidal{
		background-image: url('./images/tidal-badge.png');
	}
}

.social{

}

.footer{
	width: 100%;
	max-width: 1000px;
	margin: 50px auto;
	font-size: 14px;
	display: flex;

	div:nth-child(1){
		display: flex;
		justify-content: center;
	}

	div:nth-child(2){
		margin-left: 30px;

		@include for-phone-only{
			display: none;
		}
	}

	>div{
		flex: 1;
	}

	a.facebook, a.youtube, a.instagram {
		font-size: 30px;
		color: white;
		width: 40px;
		height: 40px;
		margin-right: 15px;

		&:hover{
			color: #bc8956;
		}

		span {
			font-size: 0;
		}
	}

}

a{
	text-decoration: none;
	transition: all 0.2s ease-out;
	color: #b7bc71;

	&:hover{
		color: #bc8956;
	}
}
